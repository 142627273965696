<template>
  <div>
    <!------------ START: Variables field ------------>
    <VariablesField
      v-if="isVariablesField"
      v-model="$v.value.$model"
      :field="field"
      :field-wrapper="true"
      :show-toggle="true"
      @toggle-variables-field="toggleVariablesField"
    />
    <!------------ END: Variables field ------------>
    <!------------ START: FieldWrapper ------------>
    <FieldWrapper
      v-else
      ref="fieldWrapper"
      :field="field"
      :input-group="true"
      :variables-field-active="variablesFieldActive"
      @toggle-variables-field="toggleVariablesField"
    >
      <!------------ START: Prepend date icon ------------>
      <div
        v-if="!isVariablesField"
        ref="dateToggle"
        class="input-group-prepend"
      >
        <span
          class="input-group-text"
          :class="{ 'cursor-pointer': !isDisabled }"
        >
          <i class="fal fa-calendar" />
        </span>
      </div>
      <!------------ END: Prepend date icon ------------>
      <input
        v-if="!isVariablesField"
        ref="inputToggle"
        :value="displayValue"
        class="form-control"
        :class="{ disabled: isDisabled }"
        type="text"
        readonly
        :placeholder="$t('formHelper.selectDateTime')"
      />
      <!------------ START: Append time icon ------------>
      <div v-if="!isVariablesField" ref="timeToggle" class="input-group-append">
        <span
          class="input-group-text"
          :class="{ 'cursor-pointer': !isDisabled }"
        >
          <i class="fal fa-clock" />
        </span>
      </div>
      <!------------ END: Append time icon ------------>
      <template v-if="!isVariablesField" #hints>
        <!------------ START: Date picker ------------>
        <v-date-picker
          v-if="showDatePicker"
          ref="datePicker"
          v-model="dateModel"
          class="position-absolute border zindex-100 shadow"
          v-bind="dateOptions"
        >
          <div class="w-100 text-right">
            <button class="btn btn-clean" @click="toggleDatePicker(true)">
              {{ $t("formHelper.ok") }}
            </button>
          </div>
        </v-date-picker>
        <!------------ END: Date picker ------------>
        <!------------ START: Time picker ------------>
        <v-time-picker
          v-if="showTimePicker"
          ref="timePicker"
          v-model="timeModel"
          class="position-absolute border zindex-100 shadow"
          v-bind="timeOptions"
        >
          <div class="w-100 text-right">
            <button class="btn btn-clean" @click="toggleTimePicker">
              {{ $t("formHelper.ok") }}
            </button>
          </div>
        </v-time-picker>
        <!------------ END: Time picker ------------>
      </template>
    </FieldWrapper>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import {
  base,
  eagerValidation,
  variablesField
} from "@/components/Tools/FormHelper/Helper/mixins";
import { formatDateTime } from "@/components/Tools/FormHelper/Helper/functions";
import VariablesField from "@/components/Tools/FormHelper/Components/InputVariables";

export default {
  components: { FieldWrapper, VariablesField },
  mixins: [base, variablesField, eagerValidation],
  props: {},
  data() {
    return {
      showDatePicker: false,
      showTimePicker: false,
      dateOptions: {
        scrollable: true,
        min: this.field.min,
        max: this.field.max,
        range: this.field.range ?? false,
        locale: this.$i18n.locale
      },
      timeOptions: {
        format: "24hr",
        scrollable: true,
        min: this.field.min,
        max: this.field.max,
        "use-seconds": this.field.useSeconds ?? false,
        "allowed-hours": this.field.allowedHours,
        "allowed-minutes": this.field.allowedMinutes,
        "allowed-seconds": this.field.allowedSeconds
      }
    };
  },
  computed: {
    dateModel: {
      // Return date formatted
      get: function () {
        return this.value ? formatDateTime(this.value, "YYYY-MM-DD") : "";
      },
      // Set date to given format
      set: function (value) {
        let splits = (this.value ? this.value : "").split(" ");
        splits[0] = value;
        this.value = formatDateTime(splits.join(" "), "YYYY-MM-DD HH:mm:ss");
      }
    },
    timeModel: {
      // Return time formatted
      get: function () {
        let format = this.timeOptions["use-seconds"] ? "HH:mm:ss" : "HH:mm";
        return this.value ? formatDateTime(this.value, format) : "";
      },
      // Set time to given format
      set: function (value) {
        let splits = (this.value ? this.value : "").split(" ");
        splits[1] = value;
        this.value = formatDateTime(splits.join(" "), "YYYY-MM-DD HH:mm:ss");
      }
    },
    // Return value formatted for displaying
    displayValue: function () {
      let format = "DD.MM.YYYY HH:mm";
      // Add seconds if option is set
      if (this.timeOptions["use-seconds"]) {
        format += ":ss";
      }
      return this.value ? formatDateTime(this.value, format) : "";
    }
  },
  created() {
    window.addEventListener("click", this.onClick);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("click", this.onClick);
  },
  methods: {
    toggleDatePicker(showTimerPicker = false) {
      // Toggle picker state
      this.showDatePicker = !this.showDatePicker;
      // Add delay when showing
      if (showTimerPicker) {
        setTimeout(() => (this.showTimePicker = true), 100);
      } else {
        // Hide
        this.showTimePicker = false;
      }
    },
    toggleTimePicker() {
      // Toggle time picker
      this.showTimePicker = !this.showTimePicker;
      this.showDatePicker = false;
    },
    onClick(e) {
      if (this.isDisabled) {
        return;
      }
      if (
        this.$refs.dateToggle?.contains(e.target) ||
        this.$refs.inputToggle?.contains(e.target)
      ) {
        // If date button was clicked
        this.toggleDatePicker();
      } else if (this.$refs.timeToggle?.contains(e.target)) {
        // If time button was clicked
        this.toggleTimePicker();
      }
      // Check if picker is already hidden
      if (this.$refs.datePicker) {
        this.showDatePicker = this.$refs.datePicker.$el.contains(e.target);
      } else if (this.$refs.timePicker) {
        this.showTimePicker = this.$refs.timePicker.$el.contains(e.target);
      }
    },
    onInput(value) {
      // Format value before emitting
      const payload = this.isVariablesField
        ? value
        : formatDateTime(value, this.field.format ?? "YYYY-MM-DD HH:mm:ss") ||
          "";
      this.$emit("input", payload);
    }
  }
};
</script>

<style lang="scss">
.zindex-100 {
  z-index: 100;
}
</style>
